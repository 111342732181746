import "./App.css";
import JobCard from "./components/JobCard";
import "./styling/main.css";
import { db } from "./configs/firebase";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  serverTimestamp,
  addDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import Specializations from "./components/specializations";
import Footer from "./components/footer";
import FooterBranding from "./components/footer_branding";
import Header from "./components/header";
import SearchOption from "./components/search_option";
import MobileApp from "./components/mobile_app";

function App() {
  const [jobList, setJobList] = useState([]);
  const [filteredJobList, setFilteredJobList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const jobsCollection = collection(db, "jobs");
  const orderedJobsQuery = query(jobsCollection, orderBy("timePosted", "desc"));

  useEffect(() => {
    const unsubscribe = onSnapshot(orderedJobsQuery, (snapshot) => {
      const jobs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log(jobs);
      setJobList(jobs);
      setFilteredJobList(jobs);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const filteredJobs = jobList.filter((job) =>
      job.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredJobList(filteredJobs);
  }, [searchTerm, jobList]);

  const [isPopupVisible, setPopupVisible] = useState(false);

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <div className="App">
      {isPopupVisible && (
        <div style={popupStyles}>
          <div style={popupContentStyles}>
            <button onClick={closePopup} style={closeButtonStyles}>
              ×
            </button>
            <MobileApp />
          </div>
        </div>
      )}
      <Header jobList={jobList} setFilteredJobList={setFilteredJobList} />
      <SearchOption setSearchTerm={setSearchTerm} />
      <Specializations
        jobList={jobList}
        setFilteredJobList={setFilteredJobList}
      />
      <div className="job__container">
        {filteredJobList.length > 0 ? (
          filteredJobList.map((job) => (
            <JobCard
              key={job.id}
              jobId={job.id}
              companyLogo={job.companyLogo}
              title={job.title}
              type={job.type}
              availability={job.availability}
              companyName={job.company}
              timePosted={job.timePosted}
              lowPayCap={job.payScaleMin}
              highPayCap={job.payScaleMax}
              noOfEmployeesLowBound={job.noOfEmployeesLowBound}
              noOfEmployeesHighBound={job.noOfEmployeesHighBound}
              views={job.views}
            />
          ))
        ) : (
          <div className="no-jobs-found">No jobs found...</div>
        )}
      </div>
      <Footer />
      <FooterBranding />
    </div>
  );
}

const popupStyles = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "1000",
};

const popupContentStyles = {
  position: "relative",
  background: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
};

const closeButtonStyles = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "transparent",
  border: "none",
  fontSize: "24px",
  cursor: "pointer",
};

export default App;
