import React from "react";
import "../styling/search_option.css";
import { Menu, Home, Search, ArrowDropDown } from "@mui/icons-material";

function SearchOption({ setSearchTerm }) {
  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="search">
      <div className="search__option__left"></div>
      <div className="search__option__middle">
        <Search />
        <input
          placeholder="Search roles here"
          type="text"
          onChange={handleSearchInputChange}
        />
      </div>
      <div className="search__option__right"></div>
    </div>
  );
}

export default SearchOption;
