import React from "react";
import { Link } from "react-router-dom";
import "../styling/main.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h3 className="footer-title">Jobs by Category</h3>
        <ul>
          <li>Front-end Jobs</li>
          <li>Backend Jobs</li>
          <li>Data Jobs</li>
          <li>Design Jobs</li>
        </ul>
      </div>
      <div className="footer-section">
        <h3 className="footer-title">For Recruiters</h3>
        <ul>
          <li>Post a Remote Job</li>
          <li>Recruiter FAQ</li>
          <li>Log in</li>
        </ul>
      </div>
      <div className="footer-section">
        <h3 className="footer-title">For Talent</h3>
        <ul className="footer-title">
          <li>Browse Remote Companies</li>
          <li>Browse Remote Jobs</li>
          <li>Candidate FAQ</li>
          <li>Job Alerts</li>
        </ul>
      </div>
      <div className="footer-section">
        <h3 className="footer-title">Company</h3>
        <ul>
          <li>About Us</li>
          <li>Contact Us</li>
          <li>Advertise</li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
