import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAW4AQE3kqtdPRHONNbSWptzKGpA24BUGk",
  authDomain: "behired-60f7f.firebaseapp.com",
  projectId: "behired-60f7f",
  storageBucket: "behired-60f7f.appspot.com",
  messagingSenderId: "935145015251",
  appId: "1:935145015251:web:b78c59eb3c114455345126",
  measurementId: "G-90K1QGHNKM",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
