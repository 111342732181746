import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faReact, faAndroid, faNodeJs, faDocker,
    faRust, faJava, faPython, faFigma, faAngular,
    faVuejs, faAws, faRaspberryPi
} from '@fortawesome/free-brands-svg-icons';
import '../styling/main.css'

const Specializations = ({ jobList, setFilteredJobList }) => {

    const handleSpecializationClick = (stack) => {
        console.log('Clicked from Specializations');
        const stackLowercase = stack.toLowerCase();
        const filteredJobs = jobList.filter(job => job.stack.toLowerCase() === stackLowercase);
        setFilteredJobList(filteredJobs);
    };

    return (
        <div className="specializations">

            <div className="specializations-icons">
                <div className="specialization" onClick={() => handleSpecializationClick('JavaScript')}>
                    <FontAwesomeIcon icon={faReact} className="icon" />
                    <p>React</p> { /* JavaScript */}
                </div>
                <div className="specialization" onClick={() => handleSpecializationClick('Mobile')}>
                    <FontAwesomeIcon icon={faAndroid} className="icon" />
                    <p>Mobile</p> { /* Mobile */}
                </div>
                <div className="specialization" onClick={() => handleSpecializationClick('Backend')}>
                    <FontAwesomeIcon icon={faRust} className='icon' />
                    <p>Ruby & Rails</p> { /* Backend */}
                </div>
                <div className="specialization" onClick={() => handleSpecializationClick('DevOps')}>
                    <FontAwesomeIcon icon={faDocker} className="icon" />
                    <p>DevOps</p> { /* DevOps */}
                </div>
                <div className='specialization' onClick={() => handleSpecializationClick('Backend')}>
                    <FontAwesomeIcon icon={faNodeJs} className="icon" />
                    <p>Node Js</p> { /* Backend */}
                </div>
                <div className='specialization' onClick={() => handleSpecializationClick('Backend')}>
                    <FontAwesomeIcon icon={faJava} className="icon" />
                    <p>Java</p> { /* Backend */}
                </div>
                <div className='specialization' onClick={() => handleSpecializationClick('Backend')}>
                    <FontAwesomeIcon icon={faPython} className="icon" />
                    <p>Python</p> { /* Backend */}
                </div>
                <div className='specialization' onClick={() => handleSpecializationClick('Design')}>
                    <FontAwesomeIcon icon={faFigma} className="icon" />
                    <p>Design</p> { /* Design */}
                </div>
                <div className='specialization' onClick={() => handleSpecializationClick('JavaScript')}>
                    <FontAwesomeIcon icon={faVuejs} className="icon" />
                    <p>Vue.js</p> { /* JavaScript */}
                </div>
                <div className='specialization' onClick={() => handleSpecializationClick('JavaScript')}>
                    <FontAwesomeIcon icon={faAngular} className="icon" />
                    <p>Angular</p> { /* JavaScript */}
                </div>
                <div className='specialization' onClick={() => handleSpecializationClick('Others')}>
                    <FontAwesomeIcon icon={faRaspberryPi} className="icon" />
                    <p>Others</p> { /* Others */}
                </div>
            </div>
        </div>
    );
};

export default Specializations;
