import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../configs/firebase";
import { doc, getDoc } from "firebase/firestore";
import moment from "moment";
import "../styling/JobDetails.css";

const JobDetails = () => {
  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const docRef = doc(db, "jobs", jobId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setJobDetails(docSnap.data());
        } else {
          console.log("No such job found!");
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  if (!jobDetails) {
    return <div>Loading...</div>;
  }

  const {
    company,
    companyCity,
    companyCountry,
    companyLogo,
    description,
    duration,
    experienceLevel,
    niceToHaveSkills,
    noOfEmployeesHighBound,
    noOfEmployeesLowBound,
    responsibilities,
    skills,
    submitUrl,
    timePosted,
    title,
    type,
  } = jobDetails;

  const timeAgo = moment(timePosted.toDate()).fromNow();

  return (
    <div className="job__details__container">
      <div className="details__header">
        <div className="company__logo">
          <img src={companyLogo} alt="Company Logo" />
        </div>
        <div className="header__text">
          <p>
            {title} - {duration}
          </p>
          <p className="company__info">
            <span className="company__name">{company}</span>
            <span className="details__location">
              {companyCity}, {companyCountry}
            </span>
          </p>
        </div>
      </div>
      <div className="job__description">
        <p>{description}</p>
      </div>
      <div className="additional__details">
        <p>
          <strong>Experience Level:</strong> {experienceLevel}
        </p>
      </div>
      <div className="skills">
        <p>
          <strong>Responsibilities:</strong>
        </p>
        <ul>
          {responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
          ))}
        </ul>
        <p>
          <strong>Skills Required:</strong>
        </p>
        <ul>
          {skills.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
        <p>
          <strong>Nice to Have Skills:</strong>
        </p>
        <ul>
          {niceToHaveSkills.map((niceToHaveSkill, index) => (
            <li key={index}>{niceToHaveSkill}</li>
          ))}
        </ul>
      </div>
      <div className="apply-button">
        <a href={submitUrl} target="_blank" rel="noopener noreferrer">
          Apply Here
        </a>
      </div>
    </div>
  );
};

export default JobDetails;
