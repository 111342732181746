import React from "react";

const MobileApp = () => {
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=com.remotejobs.android";
  return (
    <div
      style={{
        textAlign: "center",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        width: "300px",
        margin: "0 auto",
      }}
    >
      <img
        src="https://play-lh.googleusercontent.com/dafEBo_djL9_M2DjY_WdWL3GiSgQozg0Cp2LM_ydL0LiSfq9GO_bFCbCx3V_j7TYrA=w480-h960-rw"
        alt="App Logo"
        style={{ width: "100px", height: "100px", marginBottom: "20px" }}
      />
      <h2>Download Our App</h2>
      <p>Experience the best features and convenience.</p>
      <a
        href={playStoreUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <button
          style={{
            padding: "10px 20px",
            backgroundColor: "#0a74da",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Download from Google Play
        </button>
      </a>
    </div>
  );
};

export default MobileApp;
