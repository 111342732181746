import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../styling/JobCard.css";
import { Home, People } from "@mui/icons-material";
import { db } from "../configs/firebase";
import { doc, updateDoc } from "firebase/firestore";

const JobCard = ({
  jobId,
  companyLogo,
  title,
  type,
  availability,
  companyName,
  timePosted,
  lowPayCap,
  highPayCap,
  noOfEmployeesLowBound,
  noOfEmployeesHighBound,
  views,
}) => {
  const navigate = useNavigate();

  const handleJobClick = async () => {
    try {
      const jobRef = doc(db, "jobs", jobId);
      await updateDoc(jobRef, {
        views: views + 1,
      });
      navigate(`/details/${jobId}`);
    } catch (error) {
      console.error("Error updating views:", error);
    }
  };

  const timeAgo = moment(timePosted.toDate()).fromNow();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  return (
    <div className="job__card" onClick={handleJobClick}>
      <div className="card__content">
        <div className="logo-container">
          <img src={companyLogo} alt="Company Logo" />
        </div>
        <p className="title">{title}</p>
        <div className="company__size_info">
          <p>
            {" "}
            <u>{companyName}</u>
          </p>
          <People className="company__info__size__logo" />{" "}
          <p className="company__info__size__peopleNo">
            {noOfEmployeesLowBound}-{noOfEmployeesHighBound}
          </p>
        </div>

        <p className="location__availability">
          {type} - {availability}
        </p>

        <p className="jobCard__payCap">
          {formatter.format(lowPayCap)} - {formatter.format(highPayCap)} a year
        </p>

        <p className="time__ago">
          {timeAgo} - {views} {views === 0 || views > 1 ? "views" : "view"}
        </p>
      </div>
    </div>
  );
};

export default JobCard;
