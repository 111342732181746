import React from "react";
import "../styling/main.css";

const PrivacyPolicy = () => {
  const date = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        Last updated: {monthNames[date.getMonth()]} 1, {date.getFullYear()}
      </p>

      <h2>Introduction</h2>
      <p>
        Welcome to BeHiredRemote. We are committed to protecting your personal
        information and your right to privacy. If you have any questions or
        concerns about our policy, or our practices with regards to your
        personal information, please contact us at info@behiredremote.com.
      </p>

      <h2>Information We Collect</h2>
      <p>
        We collect personal information that you voluntarily provide to us when
        registering on the app, expressing an interest in obtaining information
        about us or our products and services, when participating in activities
        on the app, or otherwise contacting us.
      </p>

      <h2>How We Use Your Information</h2>
      <p>We use the information we collect or receive:</p>
      <ul>
        <li>To facilitate account creation and logon process.</li>
        <li>To send administrative information to you.</li>
        <li>To fulfill and manage your orders.</li>
        <li>To request feedback.</li>
        <li>To protect our services.</li>
      </ul>

      <h2>Sharing Your Information</h2>
      <p>
        We may process or share your data that we hold based on the following
        legal basis:
      </p>
      <ul>
        <li>
          Consent: We may process your data if you have given us specific
          consent to use your personal information in a specific purpose.
        </li>
        <li>
          Legitimate Interests: We may process your data when it is reasonably
          necessary to achieve our legitimate business interests.
        </li>
        <li>
          Performance of a Contract: Where we have entered into a contract with
          you, we may process your personal information to fulfill the terms of
          our contract.
        </li>
      </ul>

      <h2>Contact Us</h2>
      <div className="contact-info">
        <p>
          If you have questions or comments about this policy, you may email us
          at info@behiredremote.com or by post to:
        </p>
        <p>
          BeHiredRemote
          <br />
          1234 Remote St.
          <br />
          Remote City, RC 12345
          <br />
          Remote
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
