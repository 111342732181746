import React from 'react';

const FooterBranding = () => {
    const date = new Date()
    return (

        <div>
            <p>© {date.getFullYear()} Behiredremote. All rights reserved. Made with ♥, used by people worldwide.</p>
        </div>

    );
}

export default FooterBranding