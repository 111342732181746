import React from "react";
import logo from "../images/logo.png";
import "../styling/main.css";

const Header = ({ jobList, setFilteredJobList }) => {
  const handleAvailabilityClick = (availability) => {
    const availabilityLowerCase = availability.toLowerCase();
    const filteredJobs = jobList.filter(
      (job) => job.availability.toLowerCase() === availabilityLowerCase
    );
    setFilteredJobList(filteredJobs);
  };

  return (
    <header className="header">
      <div className="title">
        <img src={logo} alt="Logo" width="40" height="40" />
        <h1>Remote Jobs Worldwide</h1>
      </div>
      <nav className="menu">
        <ul>
          <li onClick={() => handleAvailabilityClick("Worldwide")}>
            Worldwide
          </li>
          <li onClick={() => handleAvailabilityClick("United States")}>
            United States
          </li>
          <li onClick={() => handleAvailabilityClick("Europe")}>Europe</li>
          <li onClick={() => handleAvailabilityClick("Australia")}>
            Australia
          </li>
          <li onClick={() => handleAvailabilityClick("Africa")}>Africa</li>
          <li onClick={() => handleAvailabilityClick("Asia")}>Asia</li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
